import React from "react"
import EventLayout from "../../components/layouts/eventLayout"
import Button from "../../components/button"

import Chamber from "../../images/capitol1.jpg"

const JuniorBoardPage = props => (
  <EventLayout pageTitle="Chamber Junior Board">


    <p>
      The Claxton-Evans County Chamber of Commerce Junior Board is a community-wide leadership program for high school juniors and seniors.  
      This two-year program is designed to develop community and civic awareness; increase understanding of local business and industry; build 
      leadership skills through hands-on learning and team building; and recognize individual leadership capacity and the ability to influence others.  
    </p><br />
    <p>
      The Junior Board program will acquaint participants with community needs, opportunities, problems and resources and provide interaction with 
      community leaders and decision makers. 
    </p><br />
    <p>
      All juniors and seniors from the Evans County School System, Pinewood Christian Academy and the Homeschool Educators Association are eligible 
      to participate.  
    </p><br />
    <p>Interested students should:</p>
    <ul css={{marginLeft: `2rem`}}>
      <li>Have a desire for leadership in school and community activities.</li>
      <li>Express interest in furthering their knowledge of local government and opportunities within the community.</li>
      <li>Commit to full participation in the two-year program.</li>
      <li>Maintain a 3.0 GPA</li>
      <li>Must have parents’ and school officials’ approval to participate. </li>
    </ul>    
    <br />
    <img
        src={Chamber}
        css={{
          border: "1px solid #000",
          width: "100%",
          marginRight: "1rem",
          marginBottom: `1rem`,
        }}
      />
{/*---
    <div css={{margin: `2rem 0`}}>
      <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
      }} href="/docs/2021-22-Jr-Board-Application.pdf">2021 - 2022 Junior Board Member Application</a>
    </div>
    --- */}
  </EventLayout>
)

export default JuniorBoardPage
